import { render, staticRenderFns } from "./updateCollectionInfo.vue?vue&type=template&id=f5a5b3ee&scoped=true&"
import script from "./updateCollectionInfo.vue?vue&type=script&lang=js&"
export * from "./updateCollectionInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5a5b3ee",
  null
  
)

export default component.exports