<template>
  <div class="applyProductCombo-container">
    <div class="comboList">
      <div class="header2 collection_header">
        <van-nav-bar
          title="编辑收款资料"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        ></van-nav-bar>
      </div>
      <van-form class="collection_cs1" validate-first style="margin-bottom: 100px">
        <van-cell-group>
          <van-cell
            title="收款账户类型:"
            class="van-ellipsis"
            @click="show1 = true"
          >
            <template #title>
                收款账户类型: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="collectionType"
                >{{ collectionType }}</span
              >
              <span style="margin-right: 15px" v-else>请选择收款类型</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show1"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="$store.state.accountStatus"
            @cancel="onCancel"
            @select="valuation"
          />
        </van-cell-group>
        <div v-if="collectionType == '公司公户'">
            <van-cell-group>
            <van-cell >
                <template #title>
                    账户名称: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.company.accountName2"
                maxlength="25"
                placeholder="请输入账户名称"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell >
                <template #title>
                    收款账号: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.company.makeAccount2"
                maxlength="50"
                placeholder="请输入收款账号"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell >
                <template #title>
                    收款开户行: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.company.openingBank2"
                maxlength="25"
                placeholder="请输入收款开户银行"
                />
            </van-cell>
            </van-cell-group>
            <van-cell class="titleImg_cs">
                <template #title>
                    开户许可证: <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.company.accountProof2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
        </div>
        <div v-if="collectionType == '法人账户'">
            <van-cell-group>
            <van-cell>
                <template #title>
                    开户人姓名: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.legal.uName"
                maxlength="30"
                placeholder="请输入开户人姓名"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell>
                <template #title>
                    银行卡卡号: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.legal.cardNumber2"
                maxlength="50"
                placeholder="请输入银行卡卡号"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell title="开户银行全称:">
                <template #title>
                    开户银行全称: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.legal.openingBank2"
                maxlength="25"
                placeholder="请输入开户银行全称"
                />
            </van-cell>
            </van-cell-group>
            <van-cell class="titleImg_cs">
                <template #title>
                    法人省份证(正面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.legal.identificationFront2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie1(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove1(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    法人省份证(反面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.legal.identificationVerso2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie2(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove2(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    银行卡: <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.legal.cardNumberImg2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie3(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove3(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    开户银行截图: <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.legal.openingBankImg2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie4(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove4(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
        </div>
        <div v-if="collectionType == '其它账户'">
            <van-cell-group>
            <van-cell title="开户人姓名:">
                <template #title>
                    开户人姓名: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.other.uName"
                maxlength="25"
                placeholder="请输入开户人姓名"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell title="收款人身份证号:">
                <template #title>
                    收款人身份证号: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.other.identityId"
                maxlength="18"
                placeholder="请输入收款人身份证号"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell title="银行卡卡号:">
                <template #title>
                    银行卡卡号: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.other.cardNumber2"
                maxlength="50"
                placeholder="请输入银行卡卡号"
                />
            </van-cell>
            </van-cell-group>
            <van-cell-group>
            <van-cell title="开户银行全称:">
                <template #title>
                    开户银行全称: <span style="color: red; font-size: 16px;">*</span>
                </template>
                <van-field
                v-model="form.other.openingBank2"
                maxlength="25"
                placeholder="请输入开户银行全称"
                />
            </van-cell>
            </van-cell-group>
            <van-cell class="titleImg_cs">
                <template #title>
                    法人省份证(正面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.identificationFront2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie5(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove5(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    法人省份证(反面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.identificationVerso2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie6(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove6(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    银行卡: <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.cardNumberImg2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie7(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove7(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    开户银行截图: <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.openingBankImg2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie8(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove8(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell class="titleImg_cs">
                <template #title>
                    收款人身份证(正面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.makeIdentificationFront2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie9(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove9(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
            <van-cell  class="titleImg_cs">
                <template #title>
                    收款人身份证(反面): <span style="color: red; font-size: 16px;">*</span>
                </template>
            <el-upload
                style="display: inline-block; padding-left: 18px"
                :limit="max"
                :action="
                $store.state.imgUpdateUrl +
                '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
                "
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :file-list="form.other.makeIdentificationVerso2"
                :on-success="
                (response) => {
                    return productRowUpdateFlie10(response);
                }
                "
                :on-exceed="fnmax"
                :on-remove="
                (file, fileList) => {
                    return productHandleRemove10(file, fileList);
                }
                "
                >
                <i class="el-icon-plus"></i>
            </el-upload>
            </van-cell>
        </div>
      </van-form>
      <van-tabbar>
        <div style="width: 100%; text-align: center">
          <van-button
            style="background-color: #8585ad; color: white; width: 20%; border-radius: 6px;"
            @click="onClickLeft"
            >取消</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            v-if="disabledBtn"
            @click="confirm"
            >提交</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            v-else
            disabled
            >提交</van-button
          >
        </div>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
let accountFormat = /^[\d]*$/;//银行账号内容格式校验
import { Empty, Notify } from "vant";
import { Toast } from "vant";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      disabledBtn: true,
      imgType: ['image/jpg','image/jpeg','image/png'],
      collectionType: '',
      form:{
        // 公司公户
        company:{
            accountName2: '',//账户名称
            makeAccount2: '',//收款账号
            openingBank2: '',//开户银行全称
            accountProof2: [],//账户凭证
        },
        // 法人账户
        legal:{
            uName: '',//开户姓名
            cardNumber2: '',//银行卡卡号
            openingBank2: '',//开户银行全称
            identificationFront2: [],//身份证正面
            identificationVerso2: [],//身份证反面
            cardNumberImg2: [],//银行卡图片
            openingBankImg2: [],//开户银行截图
        },
        // 其它账户
        other:{
            uName: '',//开户姓名
            identityId: '',//身份证号
            cardNumber2: '',//银行卡卡号
            openingBank2: '',//开户银行全称
            identificationFront2: [],//身份证正面
            identificationVerso2: [],//身份证反面
            cardNumberImg2: [],//银行卡图片
            openingBankImg2: [],//开户银行截图
            makeIdentificationFront2: [],//收款人身份证正面
            makeIdentificationVerso2: [],//收款人身份证反面
        }
      },
      show1: false,
      max: 1,
    };
  },
  created() {
      Toast.loading({
        message: '加载中...',
        overlay: true,
        duration: 200,
        forbidClick: true,
      });
      setTimeout(()=>{
        this.getMerchantBankInfo();
      }, 100)
  },
  methods: {
    //  获取细分品类
    getCommodityContractType() {
      this.$http
        .ajax(
          "/ffkj-main/industryType/list?commodityContractTypeId=" +
            this.forms.commodityContractTypeId,
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.list.commodityContractTypeListId2 = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].commodityContractTypeName;
              this.list.commodityContractTypeList2.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    // 获取所属品牌
    getMerchantBrand() {
      this.$http
        .ajax(
          "/ffkj-main/merchantBrand/selector?merchantId=" +
            localStorage.getItem("merchantId"),
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.list.merchantBrandListId = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].name;
              this.list.merchantBrandList.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    // 获取收款账户类型
    valuation(item) {
      this.collectionType = item.name;
    },
    // 取消
    onCancel() {},
    //  返回事件
    onClickLeft() {
      this.$router.push({
        path: "/collectionInfo",
      });
    },
    // 最多上传数量
    fnmax(el) {
      Notify({ type: "danger", message: "最多允许上传" + this.max + "张" });
    },
    // 开户许可证上传
    productRowUpdateFlie(response) {
      this.form.company.accountProof2.push({ url: response });
    },
    // 删除开户许可证
    productHandleRemove(file, fileList) {
      this.form.company.accountProof2 = fileList;
    },
    // 法人账户(上传法人身份证正面)
    productRowUpdateFlie1(response) {
      this.form.legal.identificationFront2.push({ url: response });
    },
    //法人账户(删除法人身份证正面) 
    productHandleRemove1(file, fileList) {
      this.form.legal.identificationFront2 = fileList;
    },
    // 法人账户(上传法人身份证反面)
    productRowUpdateFlie2(response) {
      this.form.legal.identificationVerso2.push({ url: response });
    },
    //法人账户(删除法人身份证反面) 
    productHandleRemove2(file, fileList) {
      this.form.legal.identificationVerso2 = fileList;
    },
    // 法人账户(上传银行卡图片)
    productRowUpdateFlie3(response) {
      this.form.legal.cardNumberImg2.push({ url: response });
    },
    //法人账户(删除银行卡图片)
    productHandleRemove3(file, fileList) {
      this.form.legal.cardNumberImg2 = fileList;
    },
    // 法人账户(上传开户银行截图)
    productRowUpdateFlie4(response) {
      this.form.legal.openingBankImg2.push({ url: response });
    },
    // 法人账户(删除开户银行截图)
    productHandleRemove4(file, fileList) {
      this.form.legal.openingBankImg2 = fileList;
    },
    // 其它账户(上传法人身份证正面)
    productRowUpdateFlie5(response) {
      this.form.other.identificationFront2.push({ url: response });
    },
    // 其它账户(删除法人身份证正面)
    productHandleRemove5(file, fileList) {
      this.form.other.identificationFront2 = fileList;
    },
    // 其它账户(上传法人身份证反面)
    productRowUpdateFlie6(response) {
      this.form.other.identificationVerso2.push({ url: response });
    },
    // 其它账户(删除法人身份证反面)
    productHandleRemove6(file, fileList) {
      this.form.other.identificationVerso2 = fileList;
    },
    // 其它账户(上传银行卡图片)
    productRowUpdateFlie7(response) {
      Toast.loading({
        message: '系统正在识别中,请稍后...',
        forbidClick: true,
        duration: 1200,
        loadingType: 'spinner',
      });
      this.$http
      .ajax(
          '/ffkj-main/merchant/getBankCardDistinguish',
          {
              imgUrl: response
          },
          'get',
          {}
      )
      .then((res) => {
          if (res.code == 0) {
              if(this.form.other.cardNumber2 == ''){
                  Dialog.confirm({
                    title: '银行卡识别成功',
                    message: '您是否需要自动填入识别的银行卡信息',
                  })
                  .then(() => {
                      this.form.other.cardNumber2 = res.data.bankCardNumber;
                  })
                  .catch(() => {
                    // on cancel
                  });
              }else{
                  Dialog.confirm({
                    title: '银行卡识别成功',
                    message: '您是否需要覆盖已有的银行卡信息',
                  })
                  .then(() => {
                      this.form.other.cardNumber2 = res.data.bankCardNumber;
                  })
                  .catch(() => {
                    // on cancel
                  });
              }
          } else {
              Notify({ type: 'danger', message: res.message});
          }
          var timer = setTimeout(
              function () {
                  window.clearTimeout(timer);
              }.bind(this),
              300
          );
      });
      this.form.other.cardNumberImg2.push({ url: response });
    },
    // 其它账户(删除银行卡图片)
    productHandleRemove7(file, fileList) {
      this.form.other.cardNumberImg2 = fileList;
    },
    // 其它账户(上传开户银行截图)
    productRowUpdateFlie8(response) {
      this.form.other.openingBankImg2.push({ url: response });
    },
    // 其它账户(删除开户银行截图)
    productHandleRemove8(file, fileList) {
      this.form.other.openingBankImg2 = fileList;
    },
    // 其它账户(上传收款人身份证正面)
    productRowUpdateFlie9(response) {
      Toast.loading({
        message: '系统正在识别中,请稍后...',
        forbidClick: true,
        duration: 1200,
        loadingType: 'spinner',
      });
      this.$http
      .ajax(
          '/ffkj-main/merchant/getIdCardDistinguish',
          {
              imgUrl: response
          },
          'get',
          {}
      )
      .then((res) => {
          if (res.code == 0) {
              if(this.form.other.uName == '' &&  this.form.other.identityId == ''){
                  Dialog.confirm({
                    title: '身份证识别成功',
                    message: '您是否需要自动填入识别的收款人身份证信息',
                  })
                  .then(() => {
                      this.form.other.uName = res.data.name;
                      this.form.other.identityId = res.data.idNum;
                  })
                  .catch(() => {
                    // on cancel
                  });
              }else{
                  Dialog.confirm({
                    title: '身份证识别成功',
                    message: '您是否需要覆盖已有的收款人身份证信息',
                  })
                  .then(() => {
                      this.form.other.uName = res.data.name;
                      this.form.other.identityId = res.data.idNum;
                  })
                  .catch(() => {
                    // on cancel
                  });
              }
          } else {
              Notify({ type: 'danger', message: res.message});
          }
          var timer = setTimeout(
              function () {
                  window.clearTimeout(timer);
              }.bind(this),
              300
          );
      });
      this.form.other.makeIdentificationFront2.push({ url: response });
    },
    // 其它账户(删除收款人身份证正面)
    productHandleRemove9(file, fileList) {
      this.form.other.makeIdentificationFront2 = fileList;
    },
    // 其它账户(上传收款人身份证反面)
    productRowUpdateFlie10(response) {
      this.form.other.makeIdentificationVerso2.push({ url: response });
    },
    // 其它账户(删除收款人身份证反面)
    productHandleRemove10(file, fileList) {
      this.form.other.makeIdentificationVerso2 = fileList;
    },
    beforeAvatarUpload(file) {
      if ( this.imgType.indexOf(file.type) == -1 ) {
          Notify({ type: 'danger', message: '上传图片只能是JPG/JPEG/PNG格式',duration: 1500});
          return false
      }
      if ( file.size / 1024 / 1024 > 50 ) {
          Notify({ type: 'danger', message: '上传图片大小不能超过50MB',duration: 1500});
          return false
      }
    },
    // 提交审核
    confirm() {
        if(this.collectionType == '公司公户'){
            if (!this.form.company.accountName2 || this.form.company.accountName2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入账户名称!!!" });
              return false;
            }
            if (!this.form.company.makeAccount2 || this.form.company.makeAccount2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入收款账号!!!" });
              return false;
            }
            if (!this.form.company.openingBank2 || this.form.company.openingBank2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入开户行全称!!!" });
              return false;
            }
            if (this.form.company.accountProof2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传开户许可证!!!" });
              return false;
            }
            if(this.form.company.makeAccount2.length < 9){
              Notify({ type: "danger", message: "银行账号长度不够,请检查账号长度!!!" });
              return false;
            }
            var numRe = new RegExp(accountFormat)
            if (!numRe.test(this.form.company.makeAccount2)) {
              Notify({ type: "danger", message: "银行账号格式有误,请检查账号格式!!!" });
              return false;
            }
            this.disabledBtn = false,
            this.$http
              .ajax(
                  '/ffkj-main/merchant/updateMerchantBankInfo',
                  {
                      id: localStorage.getItem('merchantId'),//商家id
                      accountType: 1,//账号类型
                      receivingUnitName: this.form.company.accountName2,//账户名称
                      receivingUnitAccount: this.form.company.makeAccount2,//收款账号
                      receivingUnitOpenBankName: this.form.company.openingBank2,//开户银行
                      accountCertification: this.form.company.accountProof2[0].url,//账户凭证
                  },
                  'post',
                  {}
              )
              .then((res) => {
                  if (res.code == 0) {
                      if(res.message == 'success'){
                          Notify({ type: "success", message: '操作成功' }); 
                          setTimeout(()=>{
                            this.onClickLeft();
                          }, 200)
                      }else{
                        this.disabledBtn = true;
                          Notify({ type: "danger", message: res.message }); 
                      }
                  } else {
                      this.disabledBtn = true;
                      Notify({ type: "danger", message: res.message }); 
                  }
                  var timer = setTimeout(
                      function () {
                          this.loading = false;
                          window.clearTimeout(timer);
                      }.bind(this),
                      300
                  );
              });
        }else if(this.collectionType == '法人账户'){
            if (!this.form.legal.uName || this.form.legal.uName.trim().length == 0) {
              Notify({ type: "danger", message: "请输入开户人姓名!!!" });
              return false;
            }
            if (!this.form.legal.cardNumber2 || this.form.legal.cardNumber2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入银行卡卡号!!!" });
              return false;
            }
            if (!this.form.legal.openingBank2 || this.form.legal.openingBank2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入开户银行全称!!!" });
              return false;
            }
            if (this.form.legal.identificationFront2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传法人身份证(正面)!!!" });
              return false;
            }
            if (this.form.legal.identificationVerso2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传法人身份证(反面)!!!" });
              return false;
            }
            if (this.form.legal.cardNumberImg2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传银行卡图片!!!" });
              return false;
            }
            if (this.form.legal.openingBankImg2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传开户银行截图!!!" });
              return false;
            }
            if(this.form.legal.cardNumber2.length < 9){
              Notify({ type: "danger", message: "银行账号长度不够,请检查账号长度!!!" });
              return false;
            }
            var numRe = new RegExp(accountFormat)
            if (!numRe.test(this.form.legal.cardNumber2)) {
              Notify({ type: "danger", message: "银行账号格式有误,请检查账号格式!!!" });
              return false;
            }
            this.disabledBtn = false;
            this.$http
              .ajax(
                  '/ffkj-main/merchant/updateMerchantBankInfo',
                  {
                      id: localStorage.getItem('merchantId'),//商家id
                      receivingUnitName: this.form.legal.uName,//开户姓名
                      accountType: 2,//账号类型
                      receivingUnitAccount: this.form.legal.cardNumber2,//收款账号
                      receivingUnitOpenBankName: this.form.legal.openingBank2,//开户银行
                      idCardFront: this.form.legal.identificationFront2[0].url,//身份证正面
                      idCardBack: this.form.legal.identificationVerso2[0].url,//身份证反面
                      bankCardPic: this.form.legal.cardNumberImg2[0].url,//银行卡图片
                      bankPic:this.form.legal.openingBankImg2[0].url,//银行图片
                  },
                  'post',
                  {}
              )
              .then((res) => {
                  if (res.code == 0) {
                      if(res.message == 'success'){
                          Notify({ type: "success", message: '操作成功' }); 
                          setTimeout(()=>{
                            this.onClickLeft();
                          }, 200)
                      }else{
                          this.disabledBtn = true;
                          Notify({ type: "danger", message: res.message }); 
                      }
                  } else {
                      this.disabledBtn = true;
                      Notify({ type: "danger", message: res.message }); 
                  }
                  var timer = setTimeout(
                      function () {
                          this.loading = false;
                          window.clearTimeout(timer);
                      }.bind(this),
                      300
                  );
              });
        }else if(this.collectionType == '其它账户'){
            if (this.form.other.makeIdentificationFront2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传收款人身份证正面!!!" });
              return false;
            }
            if (!this.form.other.uName || this.form.other.uName.trim().length == 0) {
              Notify({ type: "danger", message: "请输入开户人姓名!!!" });
              return false;
            }
            if (!this.form.other.identityId || this.form.other.identityId.trim().length == 0) {
              Notify({ type: "danger", message: "请输入收款人身份证号!!!" });
              return false;
            }
            if (this.form.other.cardNumberImg2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传银行卡图片!!!" });
              return false;
            }
            if (!this.form.other.cardNumber2 || this.form.other.cardNumber2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入银行卡卡号!!!" });
              return false;
            }
            if (!this.form.other.openingBank2 || this.form.other.openingBank2.trim().length == 0) {
              Notify({ type: "danger", message: "请输入开户银行全称!!!" });
              return false;
            }
            if (this.form.other.identificationFront2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传法人身份证(正面)!!!" });
              return false;
            }
            if (this.form.other.identificationFront2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传法人身份证(反面)!!!" });
              return false;
            }
            if (this.form.other.openingBankImg2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传开户银行截图!!!" });
              return false;
            }
            if (this.form.other.makeIdentificationVerso2.length <= 0 ) {
              Notify({ type: "danger", message: "请上传收款人身份证反面!!!" });
              return false;
            }
            if(this.form.other.identityId.length < 18){
                this.$message.error('收款人身份证号长度不够,请检查身份证号长度!!!');
                return false;
            }
            if(this.form.other.cardNumber2.length < 9){
              Notify({ type: "danger", message: "银行账号长度不够,请检查账号长度!!!" });
              return false;
            }
            var numRe = new RegExp(accountFormat)
            if (!numRe.test(this.form.other.cardNumber2)) {
              Notify({ type: "danger", message: "银行账号格式有误,请检查账号格式!!!" });
              return false;
            }
            this.disabledBtn = false;
              this.$http
                .ajax(
                    '/ffkj-main/merchant/updateMerchantBankInfo',
                    {
                        id: localStorage.getItem('merchantId'),//商家id
                        idNo: this.form.other.identityId,//收款人身份证号
                        accountType: 3,//账号类型
                        receivingUnitName: this.form.other.uName,//开户姓名
                        receivingUnitAccount: this.form.other.cardNumber2,//收款账号
                        receivingUnitOpenBankName: this.form.other.openingBank2,//开户银行
                        idCardFront: this.form.other.identificationFront2[0].url,//身份证正面
                        idCardBack: this.form.other.identificationVerso2[0].url,//身份证反面
                        bankCardPic: this.form.other.cardNumberImg2[0].url,//银行卡图片
                        bankPic: this.form.other.openingBankImg2[0].url,//银行图片
                        receiverCardFront: this.form.other.makeIdentificationFront2[0].url,//收款人身份证正面
                        receiverCardBack:  this.form.other.makeIdentificationVerso2[0].url,//收款人身份证反面
                    },
                    'post',
                    {}
                )
                .then((res) => {
                    if (res.code == 0) {
                        if(res.message == 'success'){
                            Notify({ type: "success", message: '操作成功' }); 
                            setTimeout(()=>{
                              this.onClickLeft();
                            }, 200)
                        }else{
                            this.disabledBtn = true;
                            Notify({ type: "danger", message: res.message }); 
                        }
                    } else {
                      this.disabledBtn = true;
                        Notify({ type: "danger", message: res.message }); 
                    }
                    this.windowShipments = false;
                    this.getMerchantBankInfo();
                    var timer = setTimeout(
                        function () {
                            this.loading = false;
                            window.clearTimeout(timer);
                        }.bind(this),
                        300
                    );
                });
        }else{
          Notify({ type: "danger", message: "请选择收款账户类型!!!" });
        }
    },
    // 获取账户信息
    getMerchantBankInfo(){
        this.$http
        .ajax(
            '/ffkj-main/merchant/getMerchantBankInfo?merchantId='+localStorage.getItem('merchantId'),
            {},
            'get',
            {}
        )
        .then((res) => {
            if (res.code == 0) {
                this.collectionType = (res.data.accountType == 1 ? '公司公户': res.data.accountType == 2 ? '法人账户' : res.data.accountType == 3 ? '其它账户' : res.data.accountType);
                if(this.collectionType == '公司公户'){
                  this.form.company.accountName2 = res.data.receivingUnitName;
                  this.form.company.makeAccount2 = res.data.receivingUnitAccount;
                  this.form.company.openingBank2 = res.data.receivingUnitOpenBankName;
                  this.form.company.accountProof2.push({url: res.data.accountCertification});
                }else if(this.collectionType == '法人账户'){
                  this.form.legal.uName = res.data.receivingUnitName;
                  this.form.legal.cardNumber2 = res.data.receivingUnitAccount;
                  this.form.legal.openingBank2 = res.data.receivingUnitOpenBankName;
                  this.form.legal.identificationFront2.push({url: res.data.idCardFront})
                  this.form.legal.identificationVerso2.push({url: res.data.idCardBack})
                  this.form.legal.cardNumberImg2.push({url: res.data.bankCardPic})
                  this.form.legal.openingBankImg2.push({url: res.data.bankPic})
                }else if(this.collectionType == '其它账户'){
                  this.form.other.uName = res.data.receivingUnitName
                  this.form.other.identityId = res.data.idNo
                  this.form.other.cardNumber2 = res.data.receivingUnitAccount
                  this.form.other.openingBank2 = res.data.receivingUnitOpenBankName
                  this.form.other.identificationFront2.push({url: res.data.idCardFront})
                  this.form.other.identificationVerso2.push({url: res.data.idCardBack})
                  this.form.other.cardNumberImg2.push({url: res.data.bankCardPic})
                  this.form.other.openingBankImg2.push({url: res.data.bankPic})
                  this.form.other.makeIdentificationFront2.push({url: res.data.receiverCardFront})
                  this.form.other.makeIdentificationVerso2.push({url: res.data.receiverCardBack})
                }
            } else {
                Notify({ type: 'danger', message: res.message })
            }
        });
    }
  },
};
</script>

<style scoped>
</style>
